@import url("./fonts/stylesheet.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .background {
    @apply w-screen h-screen fixed left-0 top-0 right-0 bottom-0;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: rgb(160, 150, 150);
  border-radius: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #1d1a1a;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #c4c4c4;
  cursor: pointer;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  border: 1px solid #1d1a1a;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #c4c4c4;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  border: 1px solid #1d1a1a;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #c4c4c4;
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
input[type="file"]::before {
  content: "Upload";
  height: 34px;
  display: inline-block;
  background: #c4c4c4;
  border: 1px solid #999;
  border-radius: 3px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
  width: 120px;
  float: right;
  padding-left: 20px;
}

input[type="file"]:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

iframe {
  width: 0px;
  height: 0px;
  display: none;
}

/* ***************React toastify */
.Toastify__toast-theme--light {
  font-family: " Nixie One" !important;
  font-size: 1.2rem;
  padding: 0px !important;
  padding-left: 5px !important;
  margin: 0px !important;
  @media (min-width: 1920px) {
    font-size: 3rem;
  }
}

.Toastify__toast {
  max-height: 80px !important;
  min-height: 50px !important;
  min-width: 60px !important;
  width: 100%;

  @media (min-width: 1920px) {
    height: 200px;
    max-height: 160px !important;
    min-height: 150px !important;
    min-width: 120px !important;
  }
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: white;
  border: 1px solid black;
  border-radius: 25%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: rgb(238, 236, 236);
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
  border: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 10px;
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 25%;
  background: white;
}

.w-button {
    width: 70px !important;
    height: 28px !important;
    text-align: center;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #C4C4C4;
    font-weight: 900 !important;
    cursor: pointer !important;
    font-family: 'Nixie One';
}

.dropdown-color {
    background-color: #C4C4C4;
    font-family: 'Nixie One';
    z-index: 10;
    font-size: 16px;
    font-weight: 500 !important;
}

.bg-reject {
    color: white !important;
    background-color: #C1272D !important;
}

.bg-approve {
    color: white !important;
    background-color: #59981A !important;
}

.page-class {
    height: 75% !important
}

.over-flow-x {
    overflow-x: hidden !important;
}

.page-height {
    height: 200px !important;
}



@media (min-width: 640px) {
  .sm\:text-xl.statistics, .dashboard-statistics {
    font-size: 1rem !important;
    line-height: 1.35rem !important;
  }
}

@media (min-width: 768px) {
    .w-button {
        width: 100px !important;
        height: 35px !important;
        text-align: center;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #C4C4C4;
        font-weight: 900 !important;
        cursor: pointer !important;
        font-family: 'Nixie One';
    }
    .dropdown-color {
        background-color: #C4C4C4;
        font-family: 'Nixie One';
        z-index: 10;
        font-size: 18px;
        font-weight: 500 !important;
    }
}

@media (max-width: 640px) {
    .container-height {
        height: 450px !important;
    }
    .vote-button-container{
      justify-content: center !important;;
    }
    .vote-button-container .w-button{
      padding: 15px 40px !important;
      font-size: 14px !important;
    }
}